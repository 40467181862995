<template>
  <div data-cy="asset-manage-page">
    <div>
      <AssetForm :id="asset._id" v-model="form" :error="validationErrors" @save="onUpdate" />
      <div class="text-right pb-4">
        <button type="submit" class="btn btn-primary" data-cy="update-asset-btn" :disabled="loadingAction.update" @click="onUpdate">
          <span v-if="loadingAction.update"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
          <span v-else><i class="fa fa-check-circle mr-1"></i> Update Asset</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import AssetForm from '@/components/forms/AssetForm';

export default {
  name: 'AssetManage',
  components: {
    AssetForm
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      loadingAction: 'asset/loadingAction',
      validationErrors: 'asset/validationErrors'
    })
  },
  async mounted() {
    this.form = { ...this.asset };
  },
  methods: {
    ...mapActions({
      update: 'asset/update'
    }),
    async onUpdate() {
      const dataToSend = {
        ...this.form,
        floorArea: typeof this.form.floorArea === 'string' ? Number(this.form.floorArea.replace(/,/g, '')) : this.form.floorArea
      };

      const update = await this.update({ id: this.$route.params.id, data: dataToSend });

      if (update) {
        this.$toasted.success('Asset updated successfully');
      } else {
        this.$toasted.error('Failed to update asset');
      }
    }
  }
};
</script>
